import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";

const Contact = () => {
	return (
		<Layout>
			<main>
				<Helmet>
					<title>Contact us</title>
				</Helmet>

				<header className="aboveFold">
					<div className="container">
						<h1>Please, write us at:</h1>

						<p className="subtitle">
							<a href="mailto:support@sidemail.io">support@sidemail.io</a>
						</p>
					</div>
				</header>
			</main>
		</Layout>
	);
};

export default Contact;
